import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "./common/input";
import Alert from "react-bootstrap/Alert";

function EditTurma(data) {
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const [listClasses, setListClasses] = useState([]);

  const schema = yup.object().shape({
    anos: yup.number().required("Campo Obrigatório").typeError("Obrigatório"),
    turmas: yup.string().required("Campo Obrigatório"),
  });

  const ano = data.ano;
  const turma = data.turmas;
  const id_turma = data.id_turma;

  const defaultValues = {
    id_turma: id_turma,
    anos: ano,
    turmas: turma,
  };

  useEffect(() => {
    const turmas = localStorage.getItem("turmas");
    const array_turmas = JSON.parse(turmas) || [];
    setListClasses(array_turmas);
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    const updateTurma = listClasses.map((obj) => {
      if (obj.id_turma === data.id_turma) {
        return { ...obj, turmas: data.turmas, ano: data.anos };
      }
      return obj;
    });
    // Update the localStorage item
    try {
      localStorage.setItem("turmas", JSON.stringify(updateTurma));
      setShowAlert(true);
    } catch (error) {
      setShowError(true);
    }
  };

  return (
    <>
      <Alert
        show={showAlert}
        variant="success"
        dismissible
        onClose={() => window.location.reload(true)}
      >
        Foi modificado com sucesso...
      </Alert>
      <Alert
        show={showError}
        variant="danger"
        dismissible
        onClose={() => setShowError(false)}
      >
        Ocurreu um error
      </Alert>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-2">
            <Input
              className="form-control"
              id="id_turma"
              type="hidden"
              name="id_turma"
              register={register}
              label="Id"
              placeholder="ID"
            />
            <div className="form-floating mb-3 mb-md-0">
              <Input
                className="form-control"
                id="anos"
                type="text"
                name="anos"
                register={register}
                label="Ano"
                placeholder="Ano"
              />
            </div>
            <p style={{ color: "red", textAlign: "center" }}>
              {errors.anos?.message}
            </p>
          </div>
          <div className="col-md-10">
            <div className="form-floating mb-3 mb-md-0 mt-3">
              <Input
                className="form-control"
                id="turmas"
                type="text"
                name="turmas"
                register={register}
                label="Turma"
                placeholder="Turmas"
              />
              <p style={{ color: "red", textAlign: "center" }}>
                {errors.turmas?.message}
              </p>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {!showAlert ? (
              <button type="submit" className="btn btn-success">
                Modificar
              </button>
            ) : null}
            <button
              type="reset"
              className="btn btn-danger"
              onClick={() => setShowAlert(false)}
            >
              Apagar
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default EditTurma;
