import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "./common/input";
import Alert from "react-bootstrap/Alert";

function AddTurma() {
  const schema = yup.object().shape({
    ano: yup.number().required("Campo Obrigatório").typeError("Obrigatório"),
    turmas: yup.string().required("Campo Obrigatório"),
  });

  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const [listClasses, setListClasses] = useState([]);

  useEffect(() => {
    const turmas = localStorage.getItem("turmas");
    const turma = JSON.parse(turmas);
    setListClasses(turma);
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    let lastindex = listClasses.length + 1;
    const newData = { ...data, id_turma: lastindex.toString() };
    // console.log(newData);
    listClasses.push(newData);
    try {
      localStorage.setItem("turmas", JSON.stringify(listClasses));
      setShowAlert(true);
    } catch (error) {
      setShowError(true);
    }
  };

  return (
    <>
      <Alert
        show={showAlert}
        variant="success"
        dismissible
        onClose={() => window.location.reload(true)}
      >
        Foi Inserido com Sucesso
      </Alert>
      <Alert
        show={showError}
        variant="danger"
        dismissible
        onClose={() => setShowError(false)}
      >
        Ocurreu um error
      </Alert>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-2">
            <div className="form-floating mb-3 mb-md-0">
              <Input
                className="form-control"
                id="anos"
                type="text"
                name="ano"
                register={register}
                label="Ano"
                placeholder="Ano"
                autoComplete="off"
              />
            </div>
            <p style={{ color: "red", textAlign: "center" }}>
              {errors.anos?.message}
            </p>
          </div>
          <div className="col-md-10">
            <div className="form-floating mb-3 mb-md-0">
              <Input
                className="form-control"
                id="turmas"
                type="text"
                name="turmas"
                register={register}
                label="Turma"
                placeholder="Turmas"
                autoComplete="off"
              />
              <p style={{ color: "red", textAlign: "center" }}>
                {errors.turmas?.message}
              </p>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {!showAlert ? (
              <button type="submit" className="btn btn-success">
                Adicionar
              </button>
            ) : null}
            <button
              type="reset"
              className="btn btn-danger"
              onClick={() => setShowAlert(false)}
            >
              Apagar
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddTurma;
