import React, { useEffect, useRef, useState } from "react";
import { Bar, Pie } from "react-chartjs-2";
import UseModal from "./common/useModal";
import PDFVotes from "./pdfvotes";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

function Chart({ datas, filepdf, setFilePdf }) {
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [data5, setData5] = useState([]);
  const [data6, setData6] = useState([]);

  useEffect(() => {
    const votos = localStorage.getItem("votos");
    const result = JSON.parse(votos);
    const data = result.filter(
      (item) => Object.keys(item)[0] === datas.professor
    );
    const professorData = data[0][datas.professor][0];
    console.log(professorData);
    const assiduidade = professorData.assiduidade[0];
    setData1(Object.values(assiduidade).map((val) => parseInt(val)));
    const expoe = professorData.expoe[0];
    setData2(Object.values(expoe).map((val) => parseInt(val)));
    const aprende = professorData.aprende[0];
    setData3(Object.values(aprende).map((val) => parseInt(val)));
    const motivado = professorData.motivado[0];
    setData4(Object.values(motivado).map((val) => parseInt(val)));
    const relacionamento = professorData.relacionamento[0];
    setData5(Object.values(relacionamento).map((val) => parseInt(val)));
    const continuar = professorData.continuar[0];
    setData6(Object.values(continuar).map((val) => parseInt(val)));
  }, [datas]);

  useEffect(() => {
    const estudantes = localStorage.getItem("estudantes");
    const resultEstudantes = JSON.parse(estudantes);
    setTotalVoters(resultEstudantes.length - 1);
    const resultAbst = resultEstudantes.filter((item) => item.voto === "0");
    setAbstinecia(resultAbst.length - 1);
    const resultVotos = resultEstudantes.filter((item) => item.voto === "1");
    setNumbVotes(resultVotos.length);
  }, []);

  const [totalVoters, setTotalVoters] = useState([]);
  const [abstinencia, setAbstinecia] = useState([]);
  const [numbVotes, setNumbVotes] = useState([]);


  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
  );

  const colors = [
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(75, 192, 192, 1)",
    "rgba(153, 102, 255, 1)",
  ];
  //Label for chart
  const labels = ["Muito Mau", "Mau", "Satizfaz", "Bom", "Muito Bom"];
  // Opcoes do chart
  const options = [
    {
      responsive: true,

      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: "Assiduidade",
        },
      },
    },
    {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: "Expões a materia de forma clara",
        },
      },
    },
    {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: "Consegues aprender com as aulas",
        },
      },
    },
    {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: "Sentes motivado na aulas",
        },
      },
    },
    {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: "Relacionamento do professor com a turma",
        },
      },
    },
  ];
  //Valores da basedados
  const values = [data1, data2, data3, data4, data5];

  //Pie chart
  const values1 = {
    labels: ["Sim", "Nāo"],
    datasets: [
      {
        label: "Continuar",
        data: data6,
        backgroundColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)"],
        borderColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const chartRef = useRef(null);
  const pieRef = useRef(null);

  return (
    <div>
      <h1>{datas.professor}</h1>
      <div ref={chartRef}>
        {values.map((value, index) => (
          <Bar
            data={{
              labels: labels,
              datasets: [
                {
                  data: value,
                  backgroundColor: colors,
                  borderColor: colors,
                  borderWidth: 1,
                  hoverBackgroundColor: colors,
                  hoverBorderColor: colors,
                },
              ],
            }}
            options={options[index]}
            key={index}
          />
        ))}
      </div>
      <br />
      <br />
      <div className="text-center">
        {" "}
        <small>Deseja Continuar</small>
      </div>
      <div ref={pieRef}>
        <Pie data={values1} />
        <div className="text-center">
          <small style={{ fontWeight: "bold" }}>
            Sim:{data6[0]} Nāo:{data6[1]}
          </small>
        </div>
      </div>

      <UseModal
        size={"lg"}
        show={filepdf}
        close={() => setFilePdf(false)}
        body={
          <PDFVotes
            chartRef={chartRef}
            pieRef={pieRef}
            datas={datas}
            totalVoters={totalVoters}
            abstinencia={abstinencia}
            numbVotes={numbVotes}
          />
        }
        title="Avaliaçāo do Professor"
      />
      <br />
    </div>
  );
}

export default Chart;
