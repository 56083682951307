import React, { useState } from "react";
import MyNavbar from "./common/nav";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { sendEmail } from "../services/tables";
import Alert from "react-bootstrap/Alert";

function Contactos() {
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);

  
  const schema = yup.object().shape({
    nome : yup.string().required("Campo Obrigatório"),
    email: yup.string().required("Campo Obrigatório"),
  });

  const { register, handleSubmit, formState: { errors }, } = useForm({resolver: yupResolver(schema)});

  const onSubmit = async (data) => {
    await sendEmail(data).then((res) =>
      res.data ? setShowAlert(true) : setShowError(true)
    );
  };

  return (
    <div className="content">
      <MyNavbar />
      <div className="position-relative py-4 py-xl-5">
        <div className="container position-relative">
          <div className="row d-flex justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5 col-xxl-4">
              <div
                className="card mb-5"
                style={{ backgroundColor: "#9ea1a38c" }}
              >
                <div className="card-body p-sm-5">
                  <h2 style={{ color: "white" }} className="text-center mb-4">
                    Mais Informações Contactos
                  </h2>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Nome Completo"
                        {...register("nome")}
                      />
                       <p style={{ color: "#fa0000" }}>{errors.nome?.message}</p>
                    </div>
                    <div className="mb-3">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="O Seu Email (para contacto)"
                        {...register("email")}
                      />
                      <p style={{ color: "#fa0000" }}>{errors.email?.message}</p>
                    </div>
                    <div className="mb-3">
                      <textarea
                        className="form-control"
                        placeholder="Menssagem"
                        rows={6}
                        {...register("mensagem")}
                      ></textarea>
                    </div>
                    <div>
                      {showAlert || showError ? (
                        <>
                        <Alert
                          show={showAlert}
                          variant="success"
                          dismissible
                          onClose={() => window.location.reload(true)}
                        >
                          O email foi enviado com sucesso. 
                          Entraremos em contacto logo que possivel. 
                          Obrigado
                        </Alert>
                        <Alert
                          show={showError}
                          variant="danger"
                          dismissible
                          onClose={() => setShowError(false)}
                        >
                          Ocurreu um error tente de novo !!!
                        </Alert>
                      </>
                      ) : (
                        <button className="btn btn-primary d-block w-100">
                        Enviar
                      </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contactos;
