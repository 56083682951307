import React, { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";

function DelUser(data) {
  const [danger, setDanger] = useState(false);
  const [students, setStudents] = useState([]);

  useEffect(() => {
    function getStudents() {
      const estudantes = localStorage.getItem("estudantes");
      const result = JSON.parse(estudantes);
      setStudents(result);
    }
    getStudents();
  }, []);

  const onSubmit = () => {
    console.log(data);
    const objIndex = students.findIndex(
      (obj) => obj.id_alunos === data.id_alunos
    );
    if (objIndex !== -1) {
      students.splice(objIndex, 1);
    }
    try {
      localStorage.setItem("estudantes", JSON.stringify(students));
      window.location.reload(true);
    } catch (error) {
      return null;
    }
  };

  return (
    <div className="text-center">
      <Alert
        show={danger}
        variant="danger"
        dismissible
        onClose={() => setDanger(false)}
      ></Alert>
      <p>
        Tem a certeza que quer apagar permanentemente o aluno? &nbsp;&nbsp;
        <strong>
          {data.nome} {data.apelido}
        </strong>
      </p>
      <button className="btn btn-danger m-2" onClick={onSubmit}>
        Apagar
      </button>
    </div>
  );
}

export default DelUser;
