import React, { useEffect, useState } from "react";
import MyNavbar from "./common/nav";
import Table from "react-bootstrap/Table";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import UseModal from "./common/useModal";
import AddProfessor from "./addProfessor";
import Badge from "react-bootstrap/Badge";
import EditProfessor from "./editProfessor";
import DelProfessor from "./delProfessor";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Professores() {
  const [allProf, setAllProf] = useState([]);
  const [add, setAdd] = useState(false);
  const [filteredTurmas, setFilteredTurmas] = useState([]);
  const [edit, setEdit] = useState(false);
  const [editdata, setEditData] = useState({});
  const [deldata, setDelData] = useState({});
  const [del, setDel] = useState(false);
  const [isNotifying, setIsNotifying] = useState(false);

  useEffect(() => {
    function getProf() {
      const professores = localStorage.getItem("professores");
      const result = JSON.parse(professores) || [];
      setAllProf(result);
    }
    getProf();
  }, []);
  
  var prof = allProf.map((p) => p.nome);
  const uniqueProf = [...new Set(prof)];
  uniqueProf.sort((a, b) => a.localeCompare(b));

  const handleClick = (data) => {
    const filtered = allProf.filter((turma) => turma.nome === data);
    setFilteredTurmas(filtered);
  };

  const handleadd = () => {
    setAdd(true);
  };

  const handleEdit = (edit) => {
    const editFilter = allProf.filter((turma) => turma.nome === edit);
    setEdit(true);
    setEditData(editFilter);
  };

  const handleDelete = (del) => {
    const delFilter = allProf.filter((turma) => turma.nome === del);
    setDel(true);
    setDelData(delFilter);
  };

  const notify = () => {
    if (!isNotifying) {
      setIsNotifying(true);

      toast.error("DEMO APP", {
        icon: "🤭",
        onClose: () => setIsNotifying(false), // Set isNotifying to false when the toast is closed
      });
    }
  };

  return (
    <div>
      <MyNavbar />
      <ToastContainer
        transition={Zoom}
        position="top-right"
        autoClose={9000}
        hideProgressBar={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="mt-3" onClick={notify}>
        <div className="col-md-8 col-xl-6 mx-auto">
          <div className="card">
            <div className="card-header">
              <h5 className="text-center mb-0" style={{ fontWeight: "bold" }}>
                Professores
              </h5>
            </div>
            <label className="mt-1 text-center">
              <p style={{ fontStyle: "italic" }}>
                = NOME DO ESTABELECIMENTO ENSINO ={" "}
              </p>
            </label>
            <button
              className="btn btn-outline-success"
              onClick={() => handleadd()}
            >
              {" "}
              <i className="bi bi-plus-circle"> </i>Adicionar Professor
            </button>
            <UseModal
              size={"lg"}
              show={add}
              close={() => window.location.reload(true)}
              body={<AddProfessor />}
              title="Adicionar Professor"
            />
            <div className="card-body">
              <div className="mb-3"></div>
              <div className="mb-3">
                <Table responsive="sm" striped bordered hover>
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Turmas</th>
                      <th>Opções</th>
                    </tr>
                  </thead>
                  <tbody>
                    {uniqueProf.map((p, index) => {
                      return (
                        <tr key={index}>
                          <td>{p}</td>
                          <td>
                            <DropdownButton
                              id="dropdown-basic-button"
                              title="Turmas Atribuidas"
                              variant="light"
                              onClick={() => handleClick(p)}
                            >
                              {" "}
                              {filteredTurmas.map((t, index) => {
                                return (
                                  <Badge
                                    className="m-3"
                                    key={index}
                                    bg="primary"
                                  >
                                    {t.turmas}
                                  </Badge>
                                );
                              })}
                            </DropdownButton>
                          </td>
                          <td>
                            <DropdownButton
                              id="dropdown-basic-button"
                              title="Opções"
                              variant="secundary"
                            >
                              <Dropdown.Item onClick={() => handleEdit(p)}>
                                <i className="fa fa-edit">Editar</i>
                              </Dropdown.Item>
                              <UseModal
                                size={"lg"}
                                show={edit}
                                close={() => setEdit(false)}
                                body={<EditProfessor {...editdata} />}
                                title="Editar Professor"
                              />
                              <Dropdown.Item onClick={() => handleDelete(p)}>
                                <i className="fa fa-trash">Apagar</i>
                              </Dropdown.Item>
                              <UseModal
                                show={del}
                                close={() => setDel(false)}
                                body={<DelProfessor {...deldata} />}
                                title="Apagar Professor"
                              />
                            </DropdownButton>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Professores;
