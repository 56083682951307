import React from "react";

function Input({ id, label, errors, register, name, ...rest }) {
  return (
    <div>
      <div className="form-floating">
        <input {...rest} name={name} {...register(name)} />
        <label htmlFor={id}>{label}</label>
      </div>
      <p style={{ color: "red" }}>{errors?.message}</p>
    </div>
  );
}

export default Input;
