import React from "react";
import { NavLink } from "react-router-dom";

function MyNavbar() {
  return (
    <>
      <main
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          backgroundColor: "#124e6d",
        }}
      >
        <nav className="  navbar navbar-light navbar-expand-md">
          <div className="container-fluid">
            <img
              src={process.env.PUBLIC_URL + "image/idealab.png"}
              alt="logo"
              className="img-fluid."
              style={{ maxWidth: "10%", margin: "auto", paddingRight:"40px" }}
            />
            <button
              className="navbar-toggler"
              data-bs-toggle="collapse"
              data-bs-target="#navcol-1"
            >
              <span className="visually-hidden">Toggle navigation</span>
              <span className="navbar-toggler-icon"></span>
            </button>
            <div id="navcol-1" className="collapse navbar-collapse">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <NavLink className="nav-link" to="/dashboard">
                    <div
                      className="sb-nav-link-icon"
                      style={{ color: "white" }}
                    >
                      <i className="bi bi-house"></i> Pagina Principal
                    </div>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/turmas">
                    <div
                      className="sb-nav-link-icon"
                      style={{ color: "white" }}
                    >
                      <i className="bi bi-clipboard2-plus-fill"> </i> Turmas
                    </div>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/professores">
                    <div
                      className="sb-nav-link-icon"
                      style={{ color: "white" }}
                    >
                      <i className="bi bi-mortarboard"> </i>Professores
                    </div>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/estudantes">
                    <div
                      className="sb-nav-link-icon"
                      style={{ color: "white" }}
                    >
                      <i className="bi bi-people"></i> Estudandes
                    </div>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/resultados">
                    <div
                      className="sb-nav-link-icon"
                      style={{ color: "white" }}
                    >
                      <i className="bi bi-pie-chart-fill"> </i>Resultados
                    </div>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/contactos">
                    <div
                      className="sb-nav-link-icon"
                      style={{ color: "white" }}
                    >
                      <i className="bi bi-envelope-at-fill"> </i>Contacto
                    </div>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/logout">
                    <div
                      className="sb-nav-link-icon"
                      style={{ color: "white" }}
                    >
                      <i className="bi bi-box-arrow-right"> </i>Sair
                    </div>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </main>
    </>
  );
}

export default MyNavbar;
