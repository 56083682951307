import React, { useEffect, useState } from "react";
import VotingCards from "./common/votingCards";
import { useForm } from "react-hook-form";
import UseModal from "./common/useModal";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function Votos({ turma }) {

  const [turmas, setTurmas] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isNotifying, setIsNotifying] = useState(false);


  const { register, handleSubmit, reset } = useForm();

  let navigate = useNavigate();

  useEffect(() => {
    window.localStorage.setItem("Turmas", JSON.stringify(turma));
  }, [turma]);


  useEffect(()=>{
      const professores = localStorage.getItem("professores");
      const result = JSON.parse(professores);
      const turmasResult = result.filter(item => item.turmas === turma.turmas)
      setTurmas(turmasResult);
  },[turma])


  const onSubmit =  (data) => {
    data ? setShowResult(true) : setShowError(true);
  };
  

  const handleback = () => {
    reset()
    let path = "/";
    navigate(path);
  };

  const notify = () => {
    if (!isNotifying) {
      setIsNotifying(true);

      toast.error("DEMO APP", {
        icon: "🤭",
        onClose: () => setIsNotifying(false), // Set isNotifying to false when the toast is closed
      });
    }
  };

  return (
    <div onClick={notify}>
       <ToastContainer
        transition={Zoom}
        position="top-right"
        autoClose={9000}
        hideProgressBar={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <main style={{ backgroundColor: "#d2ddde" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-goup">
            <div className="container-fluid">
              <div className="col">
                <div className="form row">
                  {turmas.map((e, index) => {
                    return (
                      <VotingCards
                        key={index}
                        professor={e.nome}
                        ano={e.ano}
                        turma={e.turmas}
                        register={register}
                        id={e.id_professores}
                      />
                    );
                  })}

                  <UseModal
                    size={"lg"}
                    show={showResult}
                    close={() => {
                      setShowResult(false);
                      window.location.href = "/";
                    }}
                    body="Voto Submetido com Sucesso"
                    title="Resultado Voto"
                  />
                  <UseModal
                    size={"lg"}
                    show={showError}
                    close={() => {
                      setShowError(false);
                    }}
                    style={{ backgroundColor: "red" }}
                    body="Ocurreu um erro o voto não foi submetido tente novamente..."
                    title="Resultado Voto"
                  />

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <button className="btn btn-success mt-3 mb-3">
                      <i className="bi bi-shift"> </i>
                      Submeter
                    </button>

                    <button
                      className=" btn btn-danger mt-3 mb-3 justify-content-end"
                      onClick={handleback}
                    >
                      <i className="bi bi-arrow-left-circle"> </i>
                      Voltar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </main>
    </div>
  );
}

export default Votos;
