import React, { useState, useEffect } from "react";
import {
  getPublicar,
  getStudents,
  getClasses,
  getProf,
  getResult,
} from "../services/source";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import { Tooltip } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Home({ setTurma }) {
  const schema = yup.object().shape({
    numero: yup
      .string()
      .required("Campo Obrigatório")
      .typeError("Numero Obrigatório"),
  });

  const [showButon, setShowButton] = useState(false);
  const [showName, setShowName] = useState();
  const [showVoto, setShowVoto] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [online, setOnline] = useState();
  const [oneStudent, setOneStudent] = useState();
  const [isNotifying, setIsNotifying] = useState(false);

  useEffect(() => {
    function setInfo() {
      const resultClasses = getClasses();
      localStorage.setItem("turmas", JSON.stringify(resultClasses));
      const resultProf = getProf();
      localStorage.setItem("professores", JSON.stringify(resultProf));
      const resultStudent = getStudents();
      localStorage.setItem("estudantes", JSON.stringify(resultStudent));
      const votos = getResult();
      localStorage.setItem("votos", JSON.stringify(votos));
    }
    setInfo();
  }, []);

  useEffect(() => {
    function getOnline() {
      const result = getPublicar();
      setOnline(result[0].online);
    }
    getOnline();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const result = getStudents();
    const stud = result;
    var typenumber = data.numero;
    var obj = stud.filter((e) => {
      return e.numero === typenumber;
    });
    if (obj.length > 0) {
      setOneStudent(obj);
      setShowName(obj[0].nome);
      obj ? setShowButton(true) : setShowButton(false);
      localStorage.setItem("aluno", obj[0].numero);
    } else {
      setShowAlert(true);
    }
  };

  let navigate = useNavigate();

  const handleClick = () => {
    //Verfificar se o numero é do administrador
    if (oneStudent[0].numero.toLowerCase() === "idealab") {
      let path = "dashboard";
      navigate(path);
    } else if (online === true) {
      //verificar se o aluno ja votou
      if (oneStudent[0].voto !== "0") {
        setShowVoto(true);
      } else {
        //Enviar para o cartao de votos
        let turmas = oneStudent[0].turmas;
        setTurma({ turmas });
        let path = "votos";
        navigate(path, { turmas });
      }
    }
  };

  const notify = () => {
    if (!isNotifying) {
      setIsNotifying(true);

      toast.error("DEMO APP", {
        icon: "🤭",
        onClose: () => setIsNotifying(false), // Set isNotifying to false when the toast is closed
      });
    }
  };

  return (
    <div onClick={notify}>
      <ToastContainer
        transition={Zoom}
        position="top-right"
        autoClose={9000}
        hideProgressBar={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <main className="container-fluid">
        <div className="row mb-3 mt-5 ">
          <img
            src={process.env.PUBLIC_URL + "image/logo.png"}
            alt="logo"
            className="img-fluid"
            style={{ maxWidth: "300px", margin: "auto" }}
          />
        </div>

        {showAlert ? (
          <Alert
            className="text-center"
            variant="danger"
            dismissible
            onClose={() => {
              setShowAlert(false);
              window.location.reload();
              localStorage.removeItem("aluno");
            }}
          >
            <strong> Numero Nāo Encontrado 😭😭!!!!</strong>
          </Alert>
        ) : null}

        {showVoto ? (
          <Alert
            className="text-center"
            variant="danger"
            dismissible
            onClose={() => {
              setShowVoto(false);
              window.location.reload();
              localStorage.removeItem("aluno");
            }}
          >
            <strong> O Voto ja descarregado 😭😭!!!!</strong>
          </Alert>
        ) : null}

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row mb-3">
            <p style={{ color: "red", textAlign: "center" }}>
              {errors.numero?.message}
            </p>
            <div className="d-grid gap-2 col-5 mx-auto">
              <div className="input-group mb-3 mb-md-0">
                {!showAlert && !showVoto ? (
                  <OverlayTrigger
                    className="form-control"
                    overlay={
                      <Tooltip id="tooltip-disable">
                        Para Administração "idealab" ou coloque o numero de
                        aluno ex: "111111"
                      </Tooltip>
                    }
                  >
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Click para iniciar"
                      style={{ textAlign: "center" }}
                      {...register("numero")}
                      autoComplete="off"
                    />
                  </OverlayTrigger>
                ) : null}
              </div>
            </div>
            <div className="row mt-3">
              <div className="d-grid gap-2 col-4 mx-auto">
                <span className="badge bg-info text-dark">{showName}</span>
              </div>
            </div>
          </div>
          {/* </div> */}
          <div className="row">
            <div className="d-grid gap-2 col-5 mx-auto">
              {!showButon ? (
                <button className="btn btn-success">Encontrar</button>
              ) : null}
              {showButon ? (
                <button className="btn btn-danger" onClick={handleClick}>
                  Votar
                </button>
              ) : null}
            </div>
          </div>
        </form>
      </main>
      <footer
        style={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          margin: "auto",
          textAlign: "center",
          color: "black",
          fontStyle: "bold",
          backgroundColor: "white",
        }}
      >
        <small> Copyright © 2023 idealabangola.com 🇦🇴</small>{" "}
      </footer>
    </div>
  );
}

export default Home;
