import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "./common/input";
import Alert from "react-bootstrap/Alert";

function EditProfessor(data) {
  const [listClasses, setListClasses] = useState([]);
  const [selectedTurmas, setSelectedTurmas] = useState([]);
  const [allProf, setAllProf] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);

  const nome = data[0].nome;

  useEffect(() => {
    const turmasIds = Object.values(data).map((item) => item.turmas);
    setSelectedTurmas(turmasIds);
  }, [data]);

  const defaultValues = {
    nome: nome,
  };

  useEffect(() => {
    const turmas = localStorage.getItem("turmas");
    const array_turmas = JSON.parse(turmas) || [];
    setListClasses(array_turmas);
  }, []);

  useEffect(() => {
    function getProf() {
      const professores = localStorage.getItem("professores");
      const result = JSON.parse(professores) || [];
      setAllProf(result);
    }
    getProf();
  }, []);

  const schema = yup.object().shape({
    nome: yup.string().required("Campo Obrigatório"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  const handleTurmaCheck = (e) => {
    const idTurma = e.target.value;
    if (e.target.checked) {
      setSelectedTurmas([...selectedTurmas, idTurma]);
    } else {
      setSelectedTurmas(selectedTurmas.filter((t) => t !== idTurma));
    }
  };

  const onSubmit = (data) => {
    const dataSubmit = data.turmas.map((turma) => {
      return { nome: data.nome, turmas: turma };
    });
    const newDataTurmasSet = new Set(dataSubmit.map((obj) => obj.turmas));
    dataSubmit.forEach((obj) => {
      const matchingRecord = allProf.find(
        (profObj) => profObj.nome === obj.nome && profObj.turmas === obj.turmas
      );
      if (matchingRecord) {
        const id_professores = matchingRecord.id_professores;
        console.log("id_professores:", id_professores);
      }
      if (!matchingRecord && newDataTurmasSet.has(obj.turmas)) {
        allProf.push(obj);
      }
    });
    const recordsToRemove = allProf.filter((profObj) => {
      return (
        profObj.nome === dataSubmit[0].nome &&
        !newDataTurmasSet.has(profObj.turmas)
      );
    });
    recordsToRemove.forEach((objToRemove) => {
      const indexToRemove = allProf.findIndex(
        (profObj) =>
          profObj.nome === objToRemove.nome &&
          profObj.turmas === objToRemove.turmas
      );
      if (indexToRemove !== -1) {
        allProf.splice(indexToRemove, 1);
      }
    });

    try {
      localStorage.setItem("professores", JSON.stringify(allProf));
      setShowAlert(true);
    } catch (error) {
      setShowError(true);
    }
    console.log("Updated allProf array:", allProf);
  };

  return (
    <>
      <Alert
        show={showAlert}
        variant="success"
        dismissible
        onClose={() => window.location.reload(true)}
      >
        Foi Inserido com Sucesso
      </Alert>
      <Alert
        show={showError}
        variant="danger"
        dismissible
        onClose={() => setShowError(false)}
      >
        Ocurreu um error
      </Alert>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-12">
            <div className="form-floating mb-3 mb-md-0">
              <Input
                className="form-control"
                id="nome"
                type="text"
                name="nome"
                register={register}
                label="Nome"
                placeholder="Professor"
                disabled
              />
            </div>{" "}
            <p style={{ color: "red", textAlign: "center" }}>
              {errors.nome?.message}
            </p>
          </div>
          <p>Selecionar Turma: </p>
          <div className="col-md-10">
            {listClasses.map((c, index) => {
              return (
                <div key={index} className="form-check form-check-inline">
                  <input
                    {...register("turmas")}
                    className="form-check-input"
                    type="checkbox"
                    id="turmas"
                    value={c.turmas}
                    checked={selectedTurmas.includes(c.turmas)}
                    onChange={handleTurmaCheck}
                  />
                  <label className="form-check-label" htmlFor="turmas">
                    {c.turmas}
                  </label>
                </div>
              );
            })}
          </div>

          <div
            className="mt-3"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <button type="submit" className="btn btn-success">
              Modificar
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default EditProfessor;
