import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Logout() {
  let navigate = useNavigate();
  useEffect(() => {
    let path = "/";
    navigate(path);
  });
}

export default Logout;
