const prof = [
  {
    id_professores: "1",
    nome: "Professor 1",
    turmas: "11M",
    ano: "1",
  },
  {
    id_professores: "2",
    nome: "Professor 2",
    turmas: "11M",
    ano: "1",
  },
  {
    id_professores: "3",
    nome: "Professor 3",
    turmas: "11M",
    ano: "1",
  },
  {
    id_professores: "4",
    nome: "Professor 1",
    turmas: "21M",
    ano: "2",
  },
  {
    id_professores: "5",
    nome: "Professor 1",
    turmas: "12M",
    ano: "1",
  },
  {
    id_professores: "6",
    nome: "Professor 2",
    turmas: "12M",
    ano: "1",
  },
  {
    id_professores: "7",
    nome: "Professor 3",
    turmas: "12M",
    ano: "1",
  },
];

export function getProf() {
  return prof;
}

const online = [
  {
    online: true,
  },
];

export function getPublicar() {
  return online;
}

const aluno = [
  {
    id_alunos: "1",
    numero: "idealab",
    nome: "Administrador",
    voto: "0",
    ano: "1",
  },
  {
    id_alunos: "1",
    numero: "Idealab",
    nome: "Administrador",
    voto: "0",
    ano: "1",
  },
  {
    id_alunos: "2",
    id_turma: "1",
    numero: "111111",
    nome: "Aluno 1",
    voto: "0",
    ano: "1",
    turmas: "11M",
  },
  {
    id_alunos: "3",
    id_turma: "2",
    numero: "222222",
    nome: "Aluno 2",
    voto: "0",
    ano: "1",
    turmas: "12M",
  },
  {
    id_alunos: "4",
    id_turma: "3",
    numero: "333333",
    nome: "Aluno 3",
    voto: "0",
    ano: "3",
    turmas: "21M",
  },
  {
    id_alunos: "5",
    id_turma: "3",
    numero: "444444",
    nome: "Aluno 4",
    voto: "1",
    ano: "3",
    turmas: "21M",
  },
  {
    id_alunos: "6",
    id_turma: "3",
    numero: "555555",
    nome: "Aluno 5",
    voto: "1",
    ano: "3",
    turmas: "21M",
  },
  {
    id_alunos: "7",
    id_turma: "3",
    numero: "666666",
    nome: "Aluno 6",
    voto: "1",
    ano: "3",
    turmas: "21M",
  },
  {
    id_alunos: "8",
    id_turma: "3",
    numero: "777777",
    nome: "Aluno 7",
    voto: "1",
    ano: "3",
    turmas: "21M",
  },
];

export function getStudents() {
  return aluno;
}

const turmas = [
  {
    ano: "1",
    id_turma: "1",
    turmas: "11M",
  },
  {
    ano: "1",
    id_turma: "2",
    turmas: "12M",
  },
  {
    ano: "2",
    id_turma: "3",
    turmas: "21M",
  },
  {
    ano: "3",
    id_turma: "4",
    turmas: "31M",
  },
  {
    ano: "4",
    id_turma: "5",
    turmas: "41M",
  },
  {
    ano: "5",
    id_turma: "6",
    turmas: "51M",
  },
];

export function getClasses() {
  return turmas;
}

const votes = [
  {
    "Professor 1": [
      {
        assiduidade: [
          {
            assiduidade1: "5",
            assiduidade2: "3",
            assiduidade3: "1",
            assiduidade4: "0",
            assiduidade5: "1",
          },
        ],
        expoe: [
          {
            expoe1: "4",
            expoe2: "5",
            expoe3: "1",
            expoe4: "0",
            expoe5: "0",
          },
        ],
        aprende: [
          {
            aprende1: "4",
            aprende2: "2",
            aprende3: "3",
            aprende4: "0",
            aprende5: "1",
          },
        ],
        motivado: [
          {
            motivado1: "3",
            motivado2: "3",
            motivado3: "1",
            motivado4: "2",
            motivado5: "1",
          },
        ],
        relacionamento: [
          {
            relacionamento1: "3",
            relacionamento2: "2",
            relacionamento3: "2",
            relacionamento4: "0",
            relacionamento5: "3",
          },
        ],
        continuar: [
          {
            continuar_sim: "5",
            continuar_nao: "5",
          },
        ],
      },
    ],
  },

  {
    "Professor 2": [
      {
        assiduidade: [
          {
            assiduidade1: "2",
            assiduidade2: "1",
            assiduidade3: "0",
            assiduidade4: "0",
            assiduidade5: "1",
          },
        ],
        expoe: [
          {
            expoe1: "0",
            expoe2: "3",
            expoe3: "1",
            expoe4: "0",
            expoe5: "0",
          },
        ],
        aprende: [
          {
            aprende1: "0",
            aprende2: "0",
            aprende3: "2",
            aprende4: "1",
            aprende5: "1",
          },
        ],
        motivado: [
          {
            motivado1: "0",
            motivado2: "0",
            motivado3: "2",
            motivado4: "0",
            motivado5: "2",
          },
        ],
        relacionamento: [
          {
            relacionamento1: "0",
            relacionamento2: "2",
            relacionamento3: "0",
            relacionamento4: "0",
            relacionamento5: "2",
          },
        ],
        continuar: [
          {
            continuar_sim: "2",
            continuar_nao: "2",
          },
        ],
      },
    ],
  },
  {
    "Professor 3": [
      {
        assiduidade: [
          {
            assiduidade1: "0",
            assiduidade2: "2",
            assiduidade3: "0",
            assiduidade4: "0",
            assiduidade5: "0",
          },
        ],
        expoe: [
          {
            expoe1: "0",
            expoe2: "2",
            expoe3: "0",
            expoe4: "0",
            expoe5: "0",
          },
        ],
        aprende: [
          {
            aprende1: "0",
            aprende2: "2",
            aprende3: "0",
            aprende4: "0",
            aprende5: "0",
          },
        ],
        motivado: [
          {
            motivado1: "0",
            motivado2: "0",
            motivado3: "2",
            motivado4: "0",
            motivado5: "0",
          },
        ],
        relacionamento: [
          {
            relacionamento1: "0",
            relacionamento2: "0",
            relacionamento3: "2",
            relacionamento4: "0",
            relacionamento5: "0",
          },
        ],
        continuar: [
          {
            continuar_sim: "0",
            continuar_nao: "2",
          },
        ],
      },
    ],
  },
];

export function getResult() {
  return votes;
}
