import React from "react";

import * as htmlToImage from "html-to-image";
import {
  Image,
  Page,
  Document,
  View,
  Text,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import PDFLogo from "./common/pdfLogo";

function PDFVotes({
  chartRef,
  pieRef,
  datas,
  totalVoters,
  abstinencia,
  numbVotes,
}) 
{
  const borderColor = "#90e5fc";
  
  const styles = StyleSheet.create({
    body: {
      marginBottom: "10%",
      paddingTop: "20",
      paddingBottom: 68,
      backgroundColor: "white",
    },
    professor: {
      marginLeft: "40%",
    },
    grafic: {
      marginLeft: "50",
      marginRight: "50",
      marginBottom: "30",
    },
    pie: {
      width: "40%",
      marginLeft: "25%",
      marginTop: "5%",
      marginBottom: "20%",
    },
    cont: {
      textAlign: "center",
      fontSize: "10",
    },
    footer: {
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      fontSize: "10",
      margin: "auto",
      paddingBottom: "10",
      color: "grey",
    },
    container: {
      flexDirection: "row",
      borderBottomColor: "#0caceb",
      backgroundColor: "#0caceb",
      borderBottomWidth: 1,
      alignItems: "center",
      height: 24,
      textAlign: "center",
      fontStyle: "bold",
      flexGrow: 1,
    },
    totalalunos: {
      width: "40%",
      borderRightColor: borderColor,
      borderRightWidth: 1,
      fontSize: "14",
      color: "white",
      fontStyle: "bold",
    },
    votaram: {
      width: "40%",
      borderRightColor: borderColor,
      borderRightWidth: 1,
      fontSize: "14",
      color: "white",
      fontStyle: "bold",
    },
    absteram: {
      width: "40%",
      borderRightColor: borderColor,
      borderRightWidth: 1,
      fontSize: "14",
      color: "white",
      fontStyle: "bold",
    },
    row: {
      flexDirection: "row",
      borderBottomColor: "#bff0fd",
      borderBottomWidth: 1,
      alignItems: "center",
      height: 24,
      fontStyle: "bold",
      fontSize: "14",
    },
    rowtotalalunos: {
      width: "40%",
      textAlign: "center",
      borderRightColor: borderColor,
      borderRightWidth: 1,
      paddingLeft: 8,
    },
    rowvotaram: {
      width: "40%",
      borderRightColor: borderColor,
      borderRightWidth: 1,
      textAlign: "center",
      paddingRight: 8,
    },
    rowabsteram: {
      width: "40%",
      borderRightColor: borderColor,
      borderRightWidth: 1,
      textAlign: "center",
      paddingRight: 8,
    },
    space: {
      marginTop: "40%",
    },
  });

  const getChartImage = async (chartRef) => {
    const chartNode = chartRef.current;
    const image = await htmlToImage.toPng(chartNode);
    return image;
  };

  const getPieImage = async (pieRef) => {
    const chartNode = pieRef.current;
    const pie = await htmlToImage.toPng(chartNode);
    return pie;
  };

  const bar = getChartImage(chartRef);
  const pie = getPieImage(pieRef);

  return (
    <div className="text-center">
      <PDFViewer width={600} height={900} showToolbar>
        <Document style={styles.body}>
          <Page>
            <View fixed>{PDFLogo()}</View>
            <View>
              <Text style={styles.professor}>{datas.professor}</Text>
              <Image style={styles.grafic} src={bar} />
              <Text style={styles.cont}>Deseja Continuar</Text>
              <Image style={styles.pie} src={pie} />

              <View style={styles.container}>
                <Text style={styles.totalalunos}>Total Alunos</Text>
                <Text style={styles.votaram}>Votaram</Text>
                <Text style={styles.absteram}>Abstinência</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.rowtotalalunos}>
                  {totalVoters}
                </Text>
                <Text style={styles.rowvotaram}>
                  {" "}
                  {abstinencia}
                </Text>
                <Text style={styles.rowabsteram}>
                  {numbVotes}
                </Text>
              </View>
            </View>
            <View style={styles.space}></View>
            <View fixed style={styles.footer}>
              <Text>Copyright © 2023 idealabangola.com</Text>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
}

export default PDFVotes;
