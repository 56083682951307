import React from "react";
import { StyleSheet, Image, View, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  image: { width: "5%", marginLeft: "5%", paddingTop: "10" },
  title: {textAlign:"center", position:"absolute", marginTop:"13", marginLeft:"20%", fontSize:"14px"},
});

const PDFLogo = () => {
  return (
    <View>
      <Text style={styles.title}>
      = CURSO DO ESTABELECIMENTO ENSINO =
      </Text>
      <Image
        style={styles.image}
        src={process.env.PUBLIC_URL + "image/logo.png"}
      ></Image>
    </View>
  );
};

export default PDFLogo;
