import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "./common/input";
import Form from "react-bootstrap/Form";

import Alert from "react-bootstrap/Alert";

function AddStudent() {
  const [listClasses, setListClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    function getAulas() {
      const turmas = localStorage.getItem("turmas");
      const result = JSON.parse(turmas);
      setListClasses(result);
    }
    getAulas();
  }, []);

  useEffect(() => {
    function getStudents() {
      const estudantes = localStorage.getItem("estudantes");
      const result = JSON.parse(estudantes);
      setStudents(result);
    }
    getStudents();
  }, []);

  const schema = yup.object().shape({
    nome: yup.string().required("Campo Obrigatório"),
    numero: yup.number().required("Campo Obrigatório").typeError("Obrigatório"),
    ano: yup.number().required("Campo Obrigatório").typeError("Obrigatório"),
    turmas: yup
      .string()
      .required("Campo Obrigatório")
      .notOneOf(["Turmas"], "Campo Obrigatório"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  var anolectivo = listClasses.map((e) => e.ano);
  const unique = [...new Set(anolectivo)];

  const onSubmit = (data) => {
    console.log(data);
    let lastindex = students.length + 1;
    const newData = { ...data, id_alunos: lastindex.toString(), voto: "0" };
    students.push(newData);
    try {
      localStorage.setItem("estudantes", JSON.stringify(students));
      setShowAlert(true);
    } catch (error) {
      setShowError(true);
    }
  };

  return (
    <>
      <Alert
        show={showAlert}
        variant="success"
        dismissible
        onClose={() => window.location.reload(true)}
      >
        Foi Inserido com Sucesso
      </Alert>
      <Alert
        show={showError}
        variant="danger"
        dismissible
        onClose={() => setShowError(false)}
      >
        Ocurreu um error
      </Alert>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mb-3">
          <div className="col-md-8">
            <div className="form-floating mb-3 mb-md-0">
              <Input
                className="form-control"
                id="nome"
                type="text"
                name="nome"
                register={register}
                label="Nome"
                placeholder="Nome"
                autoComplete="off"
              />
            </div>
            <p style={{ color: "red", textAlign: "center" }}>
              {errors.nome?.message}
            </p>
          </div>
          <div className="col-md-4">
            <div className="form-floating mb-3 mb-md-0">
              <Input
                className="form-control"
                id="numero"
                type="text"
                name="numero"
                register={register}
                label="Numero"
                placeholder="Numero"
                autoComplete="off"
              />
            </div>
            <p style={{ color: "red", textAlign: "center" }}>
              {errors.numero?.message}
            </p>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <div className="form-floating mb-3 mb-md-0">
              <Form.Select {...register("ano")}>
                <option>Ano</option>
                {unique.map((e, index) => {
                  return <option key={index}>{e}</option>;
                })}
              </Form.Select>
              <label htmlFor="ano">Ano</label>
            </div>
            <p style={{ color: "red", textAlign: "center" }}>
              {errors.ano?.message}
            </p>
          </div>
          <div className="col-md-6">
            <div className="form-floating mb-3 mb-md-0">
              <Form.Select {...register("turmas")}>
                <option>Turmas</option>
                {listClasses.map((e, index) => {
                  return <option key={index}>{e.turmas}</option>;
                })}
              </Form.Select>
              <label htmlFor="turmas">Turmas</label>
            </div>
            <p style={{ color: "red", textAlign: "center" }}>
              {errors.turmas?.message}
            </p>
          </div>
        </div>
        <div className="mt-4 mb-0">
          <div className="d-grid">
            <button type="submit" className="btn btn-success">
              Adicionar Aluno
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddStudent;
