import React from "react";

function DelVotos() {
  const handleDelete = async () => {
    window.location.reload(true);
  };

  return (
    <>
      <p>Tem a certeza que deseja apagar todos os votos!!!</p>

      <button type="submit" className="btn btn-danger" onClick={handleDelete}>
        Apagar
      </button>
    </>
  );
}

export default DelVotos;
