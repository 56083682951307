import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "./common/input";
import Alert from "react-bootstrap/Alert";

function AddProfessor() {
  const schema = yup.object().shape({
    nome: yup.string().required("Campo Obrigatório"),
  });

  const [listClasses, setListClasses] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const [listProf, setListProf] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    function getAulas() {
      const aulas = localStorage.getItem("turmas");
      const result = JSON.parse(aulas);
      setListClasses(result);
    }
    getAulas();
  }, []);

  useEffect(() => {
    function getProf() {
      const professoes = localStorage.getItem("professores");
      const result = JSON.parse(professoes);
      setListProf(result);
    }
    getProf();
  }, []);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const professores = localStorage.getItem("professores");
    const result = JSON.parse(professores);
    let lastindex = result.length + 1;
    const newData = { ...data, id_professor: lastindex };
    newData.turmas.forEach((turma, index) => {
      const idProfessor = (lastindex + index).toString();
      const entryToStore = {
        id_professores: idProfessor,
        nome: data.nome,
        turmas: turma,
      };
      listProf.push(entryToStore);
      try {
        localStorage.setItem("professores", JSON.stringify(listProf));
        setShowAlert(true);
      } catch (error) {
        setShowError(true);
      }
    });
  };

  return (
    <>
      <Alert
        show={showAlert}
        variant="success"
        dismissible
        onClose={() => window.location.reload(true)}
      >
        Foi Inserido com Sucesso
      </Alert>
      <Alert
        show={showError}
        variant="danger"
        dismissible
        onClose={() => setShowError(false)}
      >
        Ocurreu um error
      </Alert>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-12">
            <div className="form-floating mb-3 mb-md-0">
              <Input
                className="form-control"
                id="nome"
                type="text"
                name="nome"
                register={register}
                label="Nome"
                placeholder="Professor"
                autocomplete="off"
              />
            </div>{" "}
            <p style={{ color: "red", textAlign: "center" }}>
              {errors.nome?.message}
            </p>
          </div>
          <p>Selecionar Turma: </p>
          <div className="col-md-10">
            {listClasses.map((c, index) => {
              return (
                <div key={index} className="form-check form-check-inline">
                  <input
                    {...register("turmas")}
                    className="form-check-input"
                    type="checkbox"
                    id="turmas"
                    value={c.turmas}
                  />
                  <label className="form-check-label" htmlFor="turmas">
                    {c.turmas}
                  </label>
                </div>
              );
            })}
          </div>
          <div
            className="mt-3"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <button type="submit" className="btn btn-success">
              Adicionar
            </button>
            <button type="reset" className="btn btn-danger">
              Apagar
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddProfessor;
