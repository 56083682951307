import React, { useEffect, useState } from "react";

function DelProfessor(data) {
  const [allProf, setAllProf] = useState([]);

  useEffect(() => {
    function getProf() {
      const professores = localStorage.getItem("professores");
      const result = JSON.parse(professores) || [];
      setAllProf(result);
    }
    getProf();
  }, []);

  console.log(allProf);

  const handleDelete = () => {
    for (var i = allProf.length - 1; i >= 0; i--) {
      var record = allProf[i];
      if (record.nome === data[0].nome) {
        // Remove the record using splice
        allProf.splice(i, 1);
      }
    }
    try {
      localStorage.setItem("professores", JSON.stringify(allProf));
      window.location.reload(true)
    } catch (error) {
     return null;
    }
    
  };

  return (
    <>
      <p>
        Tem a certeza que deseja apagar o professor{" "}
        <strong>{data[0].nome}</strong>
      </p>

      <button type="submit" className="btn btn-danger" onClick={handleDelete}>
        Apagar
      </button>
    </>
  );
}

export default DelProfessor;
