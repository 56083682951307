import React from "react";
import Form from "react-bootstrap/Form";

function VotingCards({ professor, turma, ano, register, id }) {

    var numero = localStorage.getItem("aluno");

  return (
    <>
      <div className=" mt-3">
        <div className="col-md-8 col-xl-6 mx-auto">
          <div className="card">
            <div className="card-header">
              <h5 className="text-center mb-0" style={{ fontWeight: "bold" }}>
                {professor}
              </h5>
            </div>
            <label htmlFor="" className="mt-1 text-center">
              <p style={{ fontStyle: "italic" }}>
              = CURSO DO ESTABELECIMENTO ENSINO =
              </p>
              <p style={{ fontWeight: "bold" }}>
                TURMA: {turma} - {ano} ºAno
              </p>
            </label>
            <input
              type="hidden"
              value={professor}
              {...register(`prof${id}`)}
            />
             <input
              type="hidden"
              value={turma}
              {...register('turma')}
            />
             <input
              type="hidden"
              value={ano}
              {...register('ano')}
            />
               <input
              type="hidden"
              value={numero}
              {...register('numero')}
            />

            <div className="card-body">
              <div className="mb-3">
                <p style={{ fontWeight: "bold" }}>Assiduidade</p>
                <img
                  src={process.env.PUBLIC_URL + "image/muitoMau.png"}
                  alt="muito mau"
                  className="img-fluid"
                  style={{ width: "25px", marginBottom: "5px" }}
                />
                <Form.Check
                  inline
                  type="radio"
                  value="Muito Mau" 
                  {...register(`ass${id}`)}
                />
                <img
                  src={process.env.PUBLIC_URL + "image/mau.png"}
                  alt="mau"
                  className="img-fluid"
                  style={{ width: "25px", marginBottom: "5px" }}
                />
                <Form.Check
                  inline
                  type="radio"
                  value="Mau"
                  {...register(`ass${id}`)}
                />
                <img
                  src={process.env.PUBLIC_URL + "image/satisfaz.png"}
                  alt="bom"
                  className="img-fluid"
                  style={{ width: "25px", marginBottom: "5px" }}
                />
                <Form.Check
                  inline
                  type="radio"
                  value="Satisfaz"
                  {...register(`ass${id}`)}
                />
                <img
                  src={process.env.PUBLIC_URL + "image/bom.png"}
                  alt="bom"
                  className="img-fluid"
                  style={{ width: "25px", marginBottom: "5px" }}
                />
                <Form.Check
                  inline
                  type="radio"
                  value="Bom"
                  {...register(`ass${id}`)}
                />
                <img
                  src={process.env.PUBLIC_URL + "image/muitoBom.png"}
                  alt="Muito Bom"
                  className="img-fluid"
                  style={{ width: "25px", marginBottom: "5px" }}
                />
                <Form.Check
                  inline
                  type="radio"
                  value="Muito Bom"
                  {...register(`ass${id}`)}
                />
              </div>
              <div className="mb-3">
                <p style={{ fontWeight: "bold" }}>
                  Expões a matéria de uma forma clara
                </p>
                <img
                  src={process.env.PUBLIC_URL + "image/muitoMau.png"}
                  alt="muito mau"
                  className="img-fluid"
                  style={{ width: "25px", marginBottom: "5px" }}
                />
                <Form.Check
                  inline
                  type="radio"
                  value="Muito Mau"
                  {...register(`expoe${id}`)}
                />
                <img
                  src={process.env.PUBLIC_URL + "image/mau.png"}
                  alt="mau"
                  className="img-fluid"
                  style={{ width: "25px", marginBottom: "5px" }}
                />
                <Form.Check
                  inline
                  type="radio"
                  value="Mau"
                  {...register(`expoe${id}`)}
                />
                <img
                  src={process.env.PUBLIC_URL + "image/satisfaz.png"}
                  alt="satisfaz"
                  className="img-fluid"
                  style={{ width: "25px", marginBottom: "5px" }}
                />
                <Form.Check
                  inline
                  type="radio"
                  value="Satisfaz"
                  {...register(`expoe${id}`)}
                />
                <img
                  src={process.env.PUBLIC_URL + "image/bom.png"}
                  alt="bom"
                  className="img-fluid"
                  style={{ width: "25px", marginBottom: "5px" }}
                />
                <Form.Check
                  inline
                  type="radio"
                  value="Bom"
                  {...register(`expoe${id}`)}
                />
                <img
                  src={process.env.PUBLIC_URL + "image/muitoBom.png"}
                  alt="Muito Bom"
                  className="img-fluid"
                  style={{ width: "25px", marginBottom: "5px" }}
                />
                <Form.Check
                  inline
                  type="radio"
                  value="Mau"
                  {...register(`expoe${id}`)}
                />
              </div>
              <div className="mb-3">
                <p style={{ fontWeight: "bold" }}>
                  Consegues aprender com as aulas
                </p>
                <img
                  src={process.env.PUBLIC_URL + "image/muitoMau.png"}
                  alt="muito mau"
                  className="img-fluid"
                  style={{ width: "25px", marginBottom: "5px" }}
                />
                <Form.Check
                  inline
                  type="radio"
                  value="Muito Mau"
                  {...register(`aprende${id}`)}
                />
                <img
                  src={process.env.PUBLIC_URL + "image/mau.png"}
                  alt="mau"
                  className="img-fluid"
                  style={{ width: "25px", marginBottom: "5px" }}
                />
                <Form.Check
                  inline
                  type="radio"
                  value="Mau"
                  {...register(`aprende${id}`)}
                />
                <img
                  src={process.env.PUBLIC_URL + "image/satisfaz.png"}
                  alt="satisfaz"
                  className="img-fluid"
                  style={{ width: "25px", marginBottom: "5px" }}
                />
                <Form.Check
                  inline
                  type="radio"
                  value="Satisfaz"
                  {...register(`aprende${id}`)}
                />
                <img
                  src={process.env.PUBLIC_URL + "image/bom.png"}
                  alt="bom"
                  className="img-fluid"
                  style={{ width: "25px", marginBottom: "5px" }}
                />
                <Form.Check
                  inline
                  type="radio"
                  value="Bom"
                  {...register(`aprende${id}`)}
                />
                <img
                  src={process.env.PUBLIC_URL + "image/muitoBom.png"}
                  alt="muito bom"
                  className="img-fluid"
                  style={{ width: "25px", marginBottom: "5px" }}
                />
                <Form.Check
                  inline
                  value="Muito Bom"
                  {...register(`aprende${id}`)}
                  type="radio"
                />
              </div>
              <div className="mb-3">
                <p style={{ fontWeight: "bold" }}>Sentes motivado nas aulas</p>
                <img
                  src={process.env.PUBLIC_URL + "image/muitoMau.png"}
                  alt="muito mau"
                  className="img-fluid"
                  style={{ width: "25px", marginBottom: "5px" }}
                />
                <Form.Check
                  inline
                  type="radio"
                  value="Muito Mau"
                  {...register(`motivado${id}`)}
                />
                <img
                  src={process.env.PUBLIC_URL + "image/mau.png"}
                  alt="mau"
                  className="img-fluid"
                  style={{ width: "25px", marginBottom: "5px" }}
                />
                <Form.Check
                  inline
                  type="radio"
                  value="Mau"
                  {...register(`motivado${id}`)}
                />
                <img
                  src={process.env.PUBLIC_URL + "image/satisfaz.png"}
                  alt="satisfaz"
                  className="img-fluid"
                  style={{ width: "25px", marginBottom: "5px" }}
                />
                <Form.Check
                  inline
                  type="radio"
                  value="Satisfaz"
                  {...register(`motivado${id}`)}
                />
                <img
                  src={process.env.PUBLIC_URL + "image/bom.png"}
                  alt="bom"
                  className="img-fluid"
                  style={{ width: "25px", marginBottom: "5px" }}
                />
                <Form.Check
                  inline
                  type="radio"
                  value="Bom"
                  {...register(`motivado${id}`)}
                />
                <img
                  src={process.env.PUBLIC_URL + "image/muitoBom.png"}
                  alt="muito bom"
                  className="img-fluid"
                  style={{ width: "25px", marginBottom: "5px" }}
                />
                <Form.Check
                  inline
                  type="radio"
                  value="Muito Bom"
                  {...register(`motivado${id}`)}
                />
              </div>
              <div className="mb-3">
                <p style={{ fontWeight: "bold" }}>
                  Relacionamento do professor com a turma
                </p>
                <img
                  src={process.env.PUBLIC_URL + "image/muitoMau.png"}
                  alt="muito mau"
                  className="img-fluid"
                  style={{ width: "25px", marginBottom: "5px" }}
                />
                <Form.Check
                  inline
                  type="radio"
                  value="Muito Mau"
                  {...register(`rela${id}`)}
                />
                <img
                  src={process.env.PUBLIC_URL + "image/mau.png"}
                  alt="mau"
                  className="img-fluid"
                  style={{ width: "25px", marginBottom: "5px" }}
                />
                <Form.Check
                  inline
                  value="Mau"
                  {...register(`rela${id}`)}
                  type="radio"
                />
                <img
                  src={process.env.PUBLIC_URL + "image/satisfaz.png"}
                  alt="satisfaz"
                  className="img-fluid"
                  style={{ width: "25px", marginBottom: "5px" }}
                />
                <Form.Check
                  inline
                  value="Satisfaz"
                  {...register(`rela${id}`)}
                  type="radio"
                />
                <img
                  src={process.env.PUBLIC_URL + "image/bom.png"}
                  alt="bom"
                  className="img-fluid"
                  style={{ width: "25px", marginBottom: "5px" }}
                />
                <Form.Check
                  inline
                  value="Bom"
                  {...register(`rela${id}`)}
                  type="radio"
                />
                <img
                  src={process.env.PUBLIC_URL + "image/muitoBom.png"}
                  alt="muito bom"
                  className="img-fluid"
                  style={{ width: "25px", marginBottom: "5px" }}
                />
                <Form.Check
                  inline
                  value="Muito Bom"
                  {...register(`rela${id}`)}
                  type="radio"
                />
              </div>
              <div className="mb-3">
                <p style={{ fontWeight: "bold" }}>
                  Gostaria de continuar com o professor
                </p>
                <Form.Check
                  inline
                  label="SIM"
                  value="Sim"
                  {...register(`cont${id}`)}
                  type="radio"
                />
                <Form.Check
                  inline
                  label="NÃO"
                  value="Nao"
                  {...register(`cont${id}`)}
                  type="radio"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default VotingCards;
