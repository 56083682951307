import React, { useEffect, useState } from "react";

function DelTurma(data) {
  const [listClasses, setListClasses] = useState([]);

  useEffect(() => {
    const turmas = localStorage.getItem("turmas");
    const array_turmas = JSON.parse(turmas) || [];
    setListClasses(array_turmas);
  }, []);

  // console.log(listClasses);

  const handleDelete =  () => {
    const objectIndex = listClasses.findIndex(
      (obj) => obj.id_turma === data.id_turma
    );
    // Remove the object from the array using splice
    if (objectIndex !== -1) {
      listClasses.splice(objectIndex, 1);
    }
    try {
      localStorage.setItem("turmas", JSON.stringify(listClasses));
      window.location.reload(true)
    } catch (error) {
     return null;
    }
  };

  return (
    <>
      <p>
        Tem a certeza que deseja apagar permanentemente a Turma <strong>{data.turmas}</strong>
      </p>

      <button type="submit" className="btn btn-danger" onClick={handleDelete}>
        Apagar
      </button>
    </>
  );
}

export default DelTurma;
