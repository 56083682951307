import React, { useState, useEffect } from "react";
// import {  publicar, getPublicar } from "../services/tables";
// import { getClasses, getProf, getStudents } from "../services/source";
import MyNavbar from "./common/nav";
import "../styles/dashboard.css";
import "../styles/background.css";
import Form from "react-bootstrap/Form";
import UseModal from "./common/useModal";
import DelVotos from "./delVotos";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Dashboard() {
  const [totalAlunos, setTotalAlunos] = useState();
  const [totalProf, setTotalProf] = useState();
  const [totalTurma, setTotalTurma] = useState();
  const [totalVotosEfect, setTotalVotosEfect] = useState();
  const [online, setOnline] = useState();
  const [del, setDel] = useState(false);
  const [isNotifying, setIsNotifying] = useState(false);

  useEffect(() => {
    const professores = localStorage.getItem("professores");
    const resultProf = JSON.parse(professores);
    var prof = resultProf.map((p) => p.nome);
    const uniqueProf = [...new Set(prof)];
    setTotalProf(uniqueProf.length);
    const turmas = localStorage.getItem("turmas");
    const resultTurmas = JSON.parse(turmas);
    setTotalTurma(resultTurmas.length);
    const students = localStorage.getItem("estudantes");
    const resultEstudantes = JSON.parse(students);
    setTotalAlunos(resultEstudantes.length - 1);
    const votados = resultEstudantes.filter((item) => item.voto === "1");
    setTotalVotosEfect(votados.length);
  }, []);

  const handleDelete = () => {
    setDel(true);
  };

  const handleClick = () => {
    setOnline(true);
  };

  const notify = () => {
    if (!isNotifying) {
      setIsNotifying(true);

      toast.error("DEMO APP", {
        icon: "🤭",
        onClose: () => setIsNotifying(false), // Set isNotifying to false when the toast is closed
      });
    }
  };

  return (
    <>
      <ToastContainer
        transition={Zoom}
        position="top-right"
        autoClose={9000}
        hideProgressBar={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="content" onClick={notify}>
     
        <MyNavbar />
        <div className="text-center" style={{ color: "black" }}>
          <h1>Avaliaçāo de Professores</h1>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-10">
              <div className="row">
                {/* Numero de Professores */}
                <div className="col-xl-3 col-lg-6">
                  <div className="card l-bg-green-dark">
                    <div className="card-statistic-3 p-4">
                      <div className="card-icon card-icon-large">
                        <i className="fa fa-user fa-5x"></i>
                      </div>
                      <div className="mb-4">
                        <h5 className="card-title mb-0">
                          Numero de Professores
                        </h5>
                      </div>
                      <div className="row align-items-center mb-2 d-flex">
                        <div className="col-8">
                          <h2 className="d-flex align-items-center mb-0">
                            {totalProf}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Numero de Turmas */}
                <div className="col-xl-3 col-lg-6">
                  <div className="card l-bg-cherry">
                    <div className="card-statistic-3 p-4">
                      <div className="card-icon card-icon-large">
                        <i className="fa fa-file-o fa-5x"></i>
                      </div>
                      <div className="mb-4">
                        <h5 className="card-title mb-0">Numero Turmas</h5>
                      </div>
                      <div className="row align-items-center mb-2 d-flex">
                        <div className="col-8">
                          <h2 className="d-flex align-items-center mb-0">
                            {totalTurma}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Cartao dos alunos */}
                <div className="col-xl-3 col-lg-6">
                  <div className="card l-bg-blue-dark">
                    <div className="card-statistic-3 p-4">
                      <div className="card-icon card-icon-large">
                        <i className="fa fa-users fa-5x"></i>
                      </div>
                      <div className="mb-4">
                        <h5 className="card-title mb-0">Numero Total Alunos</h5>
                      </div>
                      <div className="row align-items-center mb-2 d-flex">
                        <div className="col-8">
                          <h2 className="d-flex align-items-center mb-0">
                            {totalAlunos}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Votos Descarregados */}
                <div className="col-xl-3 col-lg-6">
                  <div className="card l-bg-orange-dark">
                    <div className="card-statistic-3 p-4">
                      <div className="card-icon card-icon-large">
                        <i className="fa fa-spinner fa-5x"></i>
                      </div>
                      <div className="mb-4">
                        <h5 className="card-title mb-0">
                          Numero de Votos Efectuados
                        </h5>
                      </div>
                      <div className="row align-items-center mb-2 d-flex">
                        <div className="col-8">
                          <h2 className="d-flex align-items-center mb-0">
                            {totalVotosEfect}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Publicar */}
                <div className="col-xl-3 col-lg-6">
                  <div className="card l-bg-brown-dark">
                    <div className="card-statistic-3 p-4">
                      <div className="card-icon card-icon-large">
                        <i className="fa fa-archive fa-5x"></i>
                      </div>
                      <div className="mb-4">
                        <h5 className="card-title mb-0">Publicar Votaçāo</h5>
                      </div>
                      <div className="row align-items-center mb-2 d-flex">
                        <div className="col-8">
                          <h2 className="d-flex align-items-center mb-0">
                            <Form>
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                label="Publicar"
                                onClick={handleClick}
                                defaultChecked={online}
                              />
                            </Form>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Apagar Votos*/}
                <div className="col-xl-3 col-lg-6">
                  <div className="card l-bg-green-dark">
                    <div className="card-statistic-3 p-4">
                      <div className="card-icon card-icon-large">
                        <i className="fa fa-times fa-5x"></i>
                      </div>
                      <div className="mb-4">
                        <h5 className="card-title mb-0">Apagar Votos</h5>
                      </div>
                      <div className="row align-items-center mb-2 d-flex">
                        <div className="col-8">
                          <h2 className="d-flex align-items-center mb-0">
                            <button
                              className="btn btn-danger"
                              onClick={() => {
                                handleDelete();
                              }}
                            >
                              Apagar
                            </button>
                          </h2>
                        </div>
                      </div>
                      <UseModal
                        size={"lg"}
                        show={del}
                        close={() => setDel(false)}
                        body={<DelVotos />}
                        title="Apagar Votos"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
