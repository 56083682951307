import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import UseModal from "./common/useModal";
import EditStudent from "./editStudent";
import DelStudent from "./delStudent";
import MyNavbar from "./common/nav";
import "../styles/background.css";
import AddStudent from "./addStudent";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function Estudantes() {
  const [listStudents, setListSutdents] = useState([]);
  const [edit, setEdit] = useState(false);
  const [editdata, setEditData] = useState({});
  const [deldata, setDelData] = useState({});
  const [del, setDel] = useState(false);
  const [add, setAdd] = useState(false);
  const [search, setSearch] = useState("");
  const [isNotifying, setIsNotifying] = useState(false);


  useEffect(()=>{
    function  getStud(){
      const estudantes = localStorage.getItem("estudantes");
      const result = JSON.parse(estudantes) || [];
      setListSutdents(result);
    } getStud();
  },[]);

  const handleEdit = (edit) => {
    setEdit(true);
    setEditData(edit);
  };

  const handleDelete = (del) => {
    setDel(true);
    setDelData(del);
  };

  const handleadd = () => {
    setAdd(true);
  };

  const notify = () => {
    if (!isNotifying) {
      setIsNotifying(true);

      toast.error("DEMO APP" , {
        icon: "🤭",
        onClose: () => setIsNotifying(false), // Set isNotifying to false when the toast is closed
      });
    }
  };

  return (
    <>
      <ToastContainer
        transition={Zoom}
        position="top-right"
        autoClose={9000}
        hideProgressBar={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="content" onClick={notify}>
      <MyNavbar />
      <div className="mt-3">
        <div className="col-md-8 col-xl-6 mx-auto">
          <div className="card">
            <div className="card-header">
              <h5 className="text-center mb-0" style={{ fontWeight: "bold" }}>
                Alunos
              </h5>
            </div>
            <label htmlFor="" className="mt-1 text-center">
              <p style={{ fontStyle: "italic" }}>
                CURSO DE ARQUITECTURA, URBANISMO E CONSTRUÇÃO
              </p>
            </label>
            <button
              className="btn btn-outline-success"
              onClick={() => handleadd()}
            >
              {" "}
              <i className="bi bi-plus-circle"> </i>Adicionar Alunos
            </button>

            <div className="ms-3 mt-2 row g-3 align-items-center">
              <div className="col-6 ">
                <div className="input-group mb-3">
                 
                  <input
                    type="text"
                    autoComplete="off"
                    id="search"
                    className="form-control"
                    placeholder="Procurar por Ano ou Nome"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  /> 
                </div>
              </div>
            </div>

            <UseModal
              size={"lg"}
              show={add}
              close={() => setAdd(false)}
              body={<AddStudent />}
              title="Adicionar Alunos"
            />
            <div className="card-body">
              <div className="mb-3">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Numero</th>
                      <th>Nome</th>
                      <th>Turma</th>
                      <th>Ano</th>
                      <th>Opcões</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listStudents
                      .filter((s) => {
                        if (
                          s.numero !== "idealab"  &&
                          s.numero !== "Idealab"  &&
                          (s.nome.toLowerCase().includes(search.toLowerCase()) ||
                            (!isNaN(parseInt(search)) && parseInt(s.ano) === parseInt(search)))
                        ) {
                          return s;
                        } else if (
                          !isNaN(parseInt(search)) &&
                          parseInt(s.ano) === parseInt(search)
                        ) {
                          return s;
                        }
                        return null;
                      }).map((m, index) => {
                        return (
                          <tr key={index}>
                            <td>{m.numero}</td>
                            <td>{m.nome}</td>
                            <td>{m.turmas}</td>
                            <td>{m.ano}</td>
                            <td>
                              <DropdownButton
                                id="dropdown-basic-button"
                                title="Opções"
                                variant="secundary"
                              >
                                {" "}
                                <Dropdown.Item onClick={() => handleEdit(m)}>
                                  <i className="fa fa-edit">Editar</i>
                                </Dropdown.Item>
                                <UseModal
                                  size={"lg"}
                                  show={edit}
                                  close={() => setEdit(false)}
                                  body={<EditStudent {...editdata} />}
                                  title="Editar Aluno"
                                />
                                <Dropdown.Item onClick={() => handleDelete(m)}>
                                  <i className="fa fa-trash">Apagar</i>
                                </Dropdown.Item>
                                <UseModal
                                  show={del}
                                  close={() => setDel(false)}
                                  body={<DelStudent {...deldata} />}
                                  title="Apagar Aluno"
                                />
                              </DropdownButton>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div></>
  );
}

export default Estudantes;
